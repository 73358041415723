/* You can add global styles to this file, and also import other style files */

.modal {
  /* tailwind's max z-index is 50 */
  /* setting this so that the modal is always on top */
  z-index: 51;
}

html,
body {
  overflow: auto;
  position: relative;
  background-color: rgb(240, 240, 240);
  font-optical-sizing: auto;
  font-style: normal;
  font-display: swap;
}

hr {
  background: #f5f5f5 !important;
}

fieldset {
  border: 1px solid #dfdfdf;
  padding: 15px;
}

legend {
  padding: 5px;
  font-size: 14px;
}

.p-none {
  padding: 0 !important;
}
.p-r-none {
  padding-right: 0 !important;
}
.m-b-none {
  margin-bottom: 0 !important;
}
.p-l-none {
  padding-left: 0 !important;
}
.m-t-sm {
  margin-top: 0.5rem !important;
}
.p-t-sm {
  padding-top: 0.5rem !important;
}
.m-r-sm {
  margin-right: 0.5rem !important;
}
.p-r-sm {
  padding-right: 0.5rem !important;
}
.m-b-sm {
  margin-bottom: 0.5rem !important;
}
.m-l-sm {
  margin-left: 0.5rem !important;
}
.p-md {
  padding: 1rem !important;
}
.m-t-md {
  margin-top: 1rem !important;
}
.m-l-md {
  margin-left: 1rem !important;
}
.p-lg {
  padding: 2rem !important;
}
.m-t-lg {
  margin-top: 2rem !important;
}

.shimmer-loader .bar {
  -webkit-animation-duration: 0.2s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f9;
  background-image: linear-gradient(
    to right,
    #f6f7f9 0%,
    #e9ebee 20%,
    #f6f7f9 40%,
    #f6f7f9 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  height: 20px;
  margin-bottom: 10px;
}

.shimmer-loader .bar:nth-child(2) {
  width: 95%;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.breadcrumb {
  border-bottom: 1px solid #ededed;
  transform: translateY(-47px);
  transition: transform 220ms ease;
}

@media only screen and (max-width: 1280px) {
  .breadcrumb {
    transform: translateY(0);
    margin-bottom: 30px !important;
  }

  body {
    padding-top: 0;
  }
}

.is-narrow-always {
  flex: none !important;
}

/* Import Tailwind Css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Technical Debt for implementing TailwindCSS and undoing */
/* some of the CSS resets that changed things slightly. */
/* This should be deleted once tailwind is in full swing. */
@layer components {
  .button {
    @apply bg-white;
  }

  .empty-state {
    margin-top: 3em !important;
    @apply mx-auto;
  }

  hr {
    display: block;
    height: 2px;
    margin: 1.5rem 0;
  }

  /* untouched valid */
  .ng-valid[required].ng-untouched .input,
  .select select.ng-untouched[required]:valid,
  .select select.ng-untouched[required].ng-valid,
  input.input.ng-untouched[required]:valid {
    @apply border-l-4 border-l-green-500 border;
  }

  /* touched valid */
  .ng-valid[required].ng-touched .input,
  .select select.ng-touched[required]:valid,
  .select select.ng-touched[required].ng-valid,
  input.input.ng-touched[required]:valid {
    @apply border-l-4 border-l-green-500 border;
  }

  /* untouched invalid */
  .ng-invalid[required].ng-touched .input,
  .select select.ng-untouched[required]:invalid,
  .select select.ng-untouched[required].ng-invalid,
  input.input.ng-untouched[required]:invalid {
    @apply border-l-4 border-l-red-600 border;
  }

  /* touched invalid */
  .ng-invalid[required].ng-untouched .input,
  .select select.ng-touched[required]:invalid,
  .select select.ng-touched[required].ng-invalid,
  input.input.ng-touched[required]:invalid {
    @apply border-l-4 border-l-red-600 border;
  }
}

@layer components {
  .ag1-table--header-height {
    /* change if you want the height of the header cells
     to be different from the body cells */
    @apply h-[45px] max-h-[45px] min-h-[45px];
  }

  .ag1-table--cell-height {
    @apply h-[45px] max-h-[45px] min-h-[45px];
  }

  .ag1-table--cell-width {
    @apply min-w-0 w-0 max-w-0;
  }

  .ag1-table--container {
    @apply h-full w-full overflow-auto bg-white p-1;
  }

  .ag1-table--table {
    @apply flex h-full w-full flex-col overflow-auto bg-white;
  }

  .ag1-table--header {
    @apply sticky top-0 z-50 w-full bg-white overflow-hidden border-b pb-2;
  }

  .ag1-table--body {
    @apply bg-white h-full w-full;
  }

  .ag1-table--header-row {
    @apply ag1-table--header-height flex w-fit items-center gap-1 bg-white;
  }

  .ag1-table--header-cell {
    @apply bg-white py-1 whitespace-nowrap flex items-center ag1-table--header-height last:mr-3 overflow-hidden border-r last:border-r-0 text-sm font-bold;
  }

  .ag1-table--header-action-cell {
    /* reason for min-w: https://css-tricks.com/flexbox-truncated-text/ */
    @apply bg-white p-1 whitespace-nowrap flex items-center gap-1 ag1-table--header-height min-w-0;
  }

  .ag1-table--header-bulk-action-container {
    @apply bg-white p-1 whitespace-nowrap flex items-center gap-1 ag1-table--header-height;
  }

  .ag1-table--body-row {
    @apply ag1-table--cell-height flex w-fit items-center gap-1 hover:bg-slate-100 min-w-[100%];
  }

  .ag1-table--body-cell {
    @apply pl-4 p-1 whitespace-nowrap flex items-center ag1-table--cell-height group-hover:bg-slate-100;
  }

  .ag1-table--body-action-cell {
    /* reason for min-w: https://css-tricks.com/flexbox-truncated-text/ */
    @apply p-1 whitespace-nowrap flex items-center ag1-table--cell-height z-50 group-hover:bg-slate-100 pr-2 min-w-0 gap-1;
  }

  .ag1-table--group-row {
    @apply flex items-center bg-white border-b shadow ag1-table--cell-height;
   }

  .ag1-table--group-cell {
    @apply flex items-center gap-1 p-1 !sticky left-0 bg-white ag1-table--cell-height border-b;
   }

  .ag1-table--nested-row {
    @apply h-[300px] bg-slate-100 p-2  overflow-auto;
  }

  :is(.ag1-table--header-cell, .ag1-table--header-action-cell, .ag1-table--body-cell, .ag1-table--body-action-cell):first-child {
    @apply sticky left-0 z-50 border-r;
  }
}

/* override browser text selection styles for that premium feel */
@layer components {
  ::selection {
    @apply bg-steel-blue-600 text-white
  }
}

/* override browser text selection styles for that premium feel */
@layer components {
  ::selection {
    @apply bg-steel-blue-600 text-white
  }
}

/* default styles for buttons */
@layer components {
  .ag1-button-shared {
    @apply
    /* typography */
    font-medium select-none whitespace-nowrap
    /* design */
    rounded border-2
    /* interaction */
    cursor-pointer outline-none
    /* focus */
    focus-visible:ring focus-visible:ring-offset-white focus-visible:ring-offset-2 ;
  }

  .ag1-button.ag1-button--primary.ag1-button--active,
  .ag1-button--small.ag1-button--primary.ag1-button--active {
    @apply
    !bg-french-blue-600 !border-french-blue-600;
  }

  .ag1-button.ag1-button--secondary.ag1-button--active,
  .ag1-button--small.ag1-button--secondary.ag1-button--active {
    @apply
    !bg-french-blue-50 !border-french-blue-50 !text-french-blue-600;
  }

  .ag1-button.ag1-button--tertiary.ag1-button--active,
  .ag1-button--small.ag1-button--tertiary.ag1-button--active {
    @apply
    !bg-french-blue-50/45 !border-transparent !text-french-blue-600
  }

  .ag1-button {
    @apply ag1-button-shared p-3 h-[2.75rem] min-w-[2.75rem];
  }

  .ag1-button--small {
    @apply ag1-button-shared p-2 h-[2.25rem] min-w-[2.25rem];
  }

  .ag1-button.ag1-button--primary, .ag1-button--small.ag1-button--primary {
    @apply
    /* default */
    bg-french-blue-500 text-white border-french-blue-500
    /* active */
    active:bg-french-blue-600 active:border-french-blue-600
    /* hover */
    hover:bg-french-blue-600 hover:border-french-blue-600
    /* disabled */
    disabled:cursor-not-allowed disabled:bg-slate-200 disabled:text-slate-400 disabled:border-slate-200 disabled:hover:bg-slate-200 disabled:hover:border-slate-200
    /* focused */
    focus-visible:ring-french-blue-500;
  }

  .ag1-button.ag1-button--secondary, .ag1-button--small.ag1-button--secondary {
    @apply
    /* default */
    bg-slate-200 text-slate-800 border-slate-200
    /* active */
    active:bg-french-blue-50 active:border-french-blue-50 active:text-french-blue-600
    /* hover */
    hover:bg-french-blue-50 hover:border-french-blue-50 hover:text-french-blue-600
    /* disabled */
    disabled:cursor-not-allowed disabled:bg-slate-200 disabled:text-slate-400 disabled:border-slate-200 disabled:hover:bg-slate-200 disabled:hover:border-slate-200
    /* focused */
    focus-visible:ring-french-blue-500;
  }

  .ag1-button.ag1-button--tertiary, .ag1-button--small.ag1-button--tertiary {
    @apply
    /* default */
    bg-transparent text-slate-800 border-transparent
    /* active */
    active:bg-french-blue-50/45 active:border-transparent active:text-french-blue-600
    /* hover */
    hover:bg-french-blue-50/45 hover:border-transparent hover:text-french-blue-600
    /* disabled */
    disabled:cursor-not-allowed disabled:text-slate-400 disabled:hover:bg-transparent disabled:hover:border-transparent
    /* focused */
    focus-visible:ring-french-blue-500;
  }

  .ag1-button.ag1-button--danger, .ag1-button--small.ag1-button--danger {
    @apply
    /* default */
    bg-white text-red-600 border-white
    /* active */
    active:bg-red-50 active:text-red-700
    /* hover */
    hover:text-red-700 hover:border-red-700
    /* disabled */
    disabled:cursor-not-allowed disabled:bg-slate-200 disabled:text-slate-400 disabled:border-slate-200 disabled:hover:bg-slate-200 disabled:hover:border-slate-200
    /* focused */
    focus-visible:ring-red-600;
  }

  .ag1-button.ag1-button--success, .ag1-button--small.ag1-button--success {
    @apply
    /* default */
    bg-white text-green-500 border-white
    /* active */
     active:bg-green-50 active:text-green-600
    /* hover */
    hover:text-green-600 hover:border-green-600
    /* disabled */
    disabled:cursor-not-allowed disabled:bg-slate-200 disabled:text-slate-400 disabled:border-slate-200 disabled:hover:bg-slate-200 disabled:hover:border-slate-200
    /* focused */
    focus-visible:ring-green-500;
  }

}

/* default styles for tabs */
@layer components {
  .ag1-tab {
    @apply px-3 py-2 cursor-pointer outline-none whitespace-nowrap select-none text-slate-700
    hover:text-black
      disabled:cursor-not-allowed disabled:text-slate-400
      focus-visible:ring focus-visible:ring-offset-white focus-visible:ring-french-blue-500 focus-visible:ring-offset-2;
  }

  .ag1-tab--active {
    @apply -mb-[3px] border-b-4 text-french-blue-500 !border-french-blue-500
    hover:text-french-blue-600 hover:border-french-blue-600;
  }
}

.ng-select.narrow-select {
  @apply min-h-[29px] h-[29px]
}
.ng-select.narrow-select .ng-select-container {
  @apply min-h-[28px] h-[28px] text-sm
}
.narrow-select {
  @apply text-sm
}

/* default styles for inputs & ng-selects */
@layer components {
  /* inputs */
  .ng-select .ng-select-container,
  input[type="text"]:not(:focus),
  input[type="password"]:not(:focus),
  input[type="email"]:not(:focus),
  input[type="number"]:not(:focus),
  input[type="url"]:not(:focus),
  input[type="date"]:not(:focus),
  input[type="datetime-local"]:not(:focus),
  input[type="month"]:not(:focus),
  input[type="week"]:not(:focus),
  input[type="time"]:not(:focus),
  input[type="search"]:not(:focus),
  input[type="tel"]:not(:focus),
  select:not(:focus),
  [multiple]:not(:focus),
  textarea:not(:focus) {
    @apply bg-white border border-slate-300 rounded outline-none;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"],
  input[type="url"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="week"],
  input[type="time"],
  input[type="search"],
  input[type="tel"],
  select,
  [multiple],
  textarea {
    @apply px-3 py-2 text-slate-900 rounded
    selection:bg-steel-blue-500 selection:text-white
    focus-visible:ring-steel-blue-500 focus-visible:border-steel-blue-500
    disabled:text-slate-400 disabled:placeholder:text-slate-400 disabled:cursor-not-allowed
    placeholder:!text-slate-500;
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder,
  input::placeholder,
  textarea::placeholder {
    @apply text-slate-600;
  }

  select {
    @apply !pr-8;
  }

  input[type="checkbox"] {
    @apply rounded;
  }

  .indeterminate,
  input[type="checkbox"]:indeterminate {
    @apply bg-french-blue-500 hover:bg-french-blue-600;
    background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjZmZmJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMycgZD0nTTYgMTBoOCcvPjwvc3ZnPg==);
  }

  input[type="checkbox"],
  input[type="radio"] {
    @apply w-5 h-5 outline-none
    checked:text-french-blue-500
    hover:text-french-blue-600
    focus:ring-0 focus:ring-transparent focus:ring-offset-0
    focus-visible:ring focus-visible:ring-offset-white focus-visible:ring-steel-blue-500 focus-visible:ring-offset-2
    disabled:cursor-not-allowed disabled:text-slate-400;
  }

  /* invalid styles */
  .ng-select.ng-invalid .ng-select-container,
  input[type="text"]:invalid,
  input[type="password"]:invalid,
  input[type="email"]:invalid,
  input[type="number"]:invalid,
  input[type="url"]:invalid,
  input[type="date"]:invalid,
  input[type="datetime-local"]:invalid,
  input[type="month"]:invalid,
  input[type="week"]:invalid,
  input[type="time"]:invalid,
  input[type="search"]:invalid,
  input[type="tel"]:invalid,
  select:invalid,
  [multiple]:invalid,
  textarea:invalid {
    @apply border-l-4 border-l-red-600 border;
  }

  input[type="checkbox"]:invalid,
  input[type="radio"]:invalid {
    @apply border-red-600 ring-red-600;
  }

  /* valid styles */
  .ng-select[required=""].ng-valid .ng-select-container.ng-has-value,
  input[type="text"]:required:valid,
  input[type="password"]:required:valid,
  input[type="email"]:required:valid,
  input[type="number"]:required:valid,
  input[type="url"]:required:valid,
  input[type="date"]:required:valid,
  input[type="datetime-local"]:required:valid,
  input[type="month"]:required:valid,
  input[type="week"]:required:valid,
  input[type="time"]:required:valid,
  input[type="search"]:required:valid,
  [multiple]:required:valid,
  select:required:valid,
  input[type="tel"]:required:valid,
  textarea:required:valid {
    @apply border-l-4 border-l-green-500 border;
  }

  .hide-number-input-arrows {
    @apply [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none
  }

  /* ng-selects */
  /* make ng-select responsive */
  textarea, input, .ng-select {
    @apply w-full min-w-0;
  }

  /* ng-select resets to match inputs */
  .ng-select .ng-select-container {
    @apply py-2 text-base;
  }
  .ng-select.ng-select-multiple .ng-select-container,
  .ng-select.ng-select-single .ng-select-container {
    @apply h-unset;
  }

  .ng-select .ng-select-container:hover {
    @apply shadow-none;
  }

  .ng-select.ng-select-mutliple .ng-select-container .ng-value-container .ng-input,
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    @apply top-unset;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    @apply py-0;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    @apply whitespace-nowrap overflow-hidden text-ellipsis;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    @apply top-unset py-0;
  }

  /* focused */
  .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container{
    @apply border shadow-none outline-none ring-1  border-steel-blue-500 ring-steel-blue-500;
  }

  .ng-select.ng-select-focused.ng-select-opened > .ng-select-container{
    @apply border border-steel-blue-500 shadow-none outline-none ring-1 ring-steel-blue-500 ;
  }

  /* required & focused */
  .ng-select[required=""].ng-select-focused:not(.ng-select-opened) > .ng-select-container.ng-has-value {
    @apply border-l-4 border-l-green-500;
  }

  .ng-select[required=""].ng-select-focused:not(.ng-select-opened) > .ng-select-container:not(.ng-has-value) {
    @apply border-l-4 border-l-red-600;
  }

  /* required & focused & open */
  .ng-select[required=""].ng-select-focused.ng-select-opened > .ng-select-container.ng-has-value {
    @apply border-l-4 border-l-green-500;
  }

  .ng-select[required=""].ng-select-focused.ng-select-opened > .ng-select-container:not(.ng-has-value) {
    @apply border-l-4 border-l-red-600;
  }

  /* required & disabled */
  .ng-select[required=""].ng-select-disabled .ng-select-container {
    @apply border-l-4;
  }

  /* multiple select pills  */
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    @apply gap-1;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    @apply bg-slate-200 rounded-full px-2 m-0 flex hover:bg-french-blue-50;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    @apply text-ellipsis overflow-x-hidden max-w-[30ch] whitespace-nowrap capitalize;
  }

  .ng-select .ng-option .ng-option-label {
    @apply capitalize;
  }

  .ng-select .ng-clear-wrapper {
    @apply text-center;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    @apply border-0 order-5;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left:hover {
    @apply bg-french-blue-50;
  }

  .ng-select .ng-arrow-wrapper {
    @apply bg-white z-0;
  }

  .ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value  {
    @apply bg-steel-blue-50;
  }

  .ng-select.ng-select-disabled .ng-arrow-wrapper {
    @apply bg-slate-50;
  }

  .ng-dropdown-panel.auto-width-panel {
    /* ng-select applies whatever width the parent ng-select has to the dropdown as an element-style */
    /* use important to override the element style */
    width: fit-content !important;
  }
}

/* default styles for scrollbar */
@layer base {
  /* width & height */
  ::-webkit-scrollbar {
    @apply w-3 h-3;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-steel-blue-200 rounded-full;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-steel-blue-300;
  }
}

/* hidden scrollbar utility */
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

/* default styles for tooltip */
@layer base {
  .ag1-tooltip {
    @apply
    /* structure */
    fixed whitespace-nowrap z-50 pointer-events-none block
    /* styling */
    p-1 rounded shadow-sm;
  }

  /* variants */
  .ag1-tooltip.ag1-tooltip--primary,
  .ag1-tooltip.ag1-tooltip--secondary,
  .ag1-tooltip.ag1-tooltip--tertiary
  {
    @apply bg-slate-800 text-white;
  }

  .ag1-tooltip.ag1-tooltip--danger {
    @apply bg-red-700 text-white;
  }

  .ag1-tooltip.ag1-tooltip--success {
    @apply bg-green-600 text-white;
  }

  /* Specific class for preserving whitespace and new lines */
  .ag1-tooltip--preserve-whitespace[data-hint]::before {
    @apply whitespace-pre; /* Preserve whitespace and wrap long text */
    @apply w-max;
  }

  .tooltip-indicator-dots,
  .ag1-tooltip.ag1-tooltip--decoration {
    @apply decoration-dotted underline-offset-1 underline;
  }

  .ag1-tooltip--arrow {
    /* structure */
    @apply border-transparent border-8 absolute pointer-events-none;
  }
  .ag1-tooltip--arrow.ag1-tooltip--arrow-top {
    /* arrow direction and position */
    @apply border-b-slate-800 bottom-full left-1/2 -translate-x-1/2;
  }
  .ag1-tooltip--arrow.ag1-tooltip--arrow-bottom {
    /* arrow direction and position */
    @apply border-t-slate-800 top-full left-1/2 -translate-x-1/2;
  }
  .ag1-tooltip--arrow.ag1-tooltip--arrow-left {
    /* arrow direction and position */
    @apply border-r-slate-800 right-full top-1/2 -translate-y-1/2;
  }
  .ag1-tooltip--arrow.ag1-tooltip--arrow-right {
    /* arrow direction and position */
    @apply border-l-slate-800 left-full top-1/2 -translate-y-1/2;
  }
}

/* default styles for checkbox toggle */
@layer base {
  .ag1-toggle {
    @apply !relative !h-6 !w-14 !rounded-full !cursor-pointer !outline-none !border-0 !appearance-none
    checked:hover:before:bg-french-blue-600 hover:before:bg-french-blue-50
    disabled:!cursor-not-allowed disabled:after:bg-slate-300
    invalid:focus:before:ring-red-600 focus:before:ring-offset-2
    invalid:before:bg-red-600 invalid:hover:before:bg-red-700
    before:pointer-events-none before:visible before:absolute before:top-1/2 before:block before:h-6 before:w-14 before:-translate-y-1/2 before:cursor-pointer before:rounded-full before:bg-slate-200
    after:pointer-events-none after:visible after:absolute after:left-1 after:top-1/2 after:block after:h-4 after:w-4 after:-translate-y-1/2 after:cursor-pointer after:rounded-full after:bg-white after:transition-all
    checked:before:bg-french-blue-500 checked:after:left-9
    focus:!ring-0 focus:!ring-transparent focus:!ring-offset-0
    focus:before:ring-french-blue-500
    focus-visible:before:ring;
  }
}

/* default styles for tags */
@layer components {
  /* badge structure */
  .ag1-badge {
    @apply px-2 py-1.5 rounded text-xs uppercase tracking-wider;
  }

  /* badge colors */
  .ag1-badge.ag1-badge-red {
    @apply bg-red-100/30 text-red-600;
  }
  .ag1-badge.ag1-badge-orange {
    @apply bg-orange-100/30 text-orange-600;
  }
  .ag1-badge.ag1-badge-amber {
    @apply bg-amber-100/30 text-amber-600;
  }
  .ag1-badge.ag1-badge-yellow {
    @apply bg-yellow-100/30 text-yellow-600;
  }
  .ag1-badge.ag1-badge-lime {
    @apply bg-lime-100/30 text-lime-600;
  }
  .ag1-badge.ag1-badge-green {
    @apply bg-green-100/30 text-green-600;
  }
  .ag1-badge.ag1-badge-emerald {
    @apply bg-emerald-100/30 text-emerald-600;
  }
  .ag1-badge.ag1-badge-teal {
    @apply bg-teal-100/30 text-teal-600;
  }
  .ag1-badge.ag1-badge-cyan {
    @apply bg-cyan-100/30 text-cyan-600;
  }
  .ag1-badge.ag1-badge-sky {
    @apply bg-sky-100/30 text-sky-600;
  }
  .ag1-badge.ag1-badge-blue {
    @apply bg-blue-100/30 text-blue-600;
  }
  .ag1-badge.ag1-badge-indigo {
    @apply bg-indigo-100/30 text-indigo-600;
  }
  .ag1-badge.ag1-badge-violet {
    @apply bg-violet-100/30 text-violet-600;
  }
  .ag1-badge.ag1-badge-purple {
    @apply bg-purple-100/30 text-purple-600;
  }
  .ag1-badge.ag1-badge-fuchsia {
    @apply bg-fuchsia-100/30 text-fuchsia-600;
  }
  .ag1-badge.ag1-badge-pink {
    @apply bg-pink-100/30 text-pink-600;
  }
  .ag1-badge.ag1-badge-rose {
    @apply bg-rose-100/30 text-rose-600;
  }
  .ag1-badge.ag1-badge-slate {
    @apply bg-slate-100/30 text-slate-600;
  }
}

/* style ngx-datatable with tailwind */
.datatable-header {
  @apply border-b-2 bg-white border-slate-200;
}

.datatable-body-row {
    @apply text-sm
      tracking-wide
      text-left
      whitespace-nowrap
      text-slate-600
      hover:bg-slate-100
      bg-white
}

.datatable-row-odd {
  @apply bg-slate-50;
}

.ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell, .ngx-datatable .datatable-body .datatable-body-row .datatable-body-group-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  display: flex;
  align-items: center;
}

.ngx-datatable .datatable-header .datatable-header-cell {
text-align: left;
padding: 0.9rem 1.2rem;
display: flex;
align-items: center;
font-weight: 600;
}

.ngx-datatable .datatable-header .resize-handle {
  border-right: solid 1px #eee;
}

.datatable-body-cell-label {
  width: 100%;
}
/* style ngx-datatable with tailwind end */

/*
for use in Angular Overlays where we specifically do not want pointer interaction
see https://stackoverflow.com/a/45617481
*/
.no-pointer-events {
  pointer-events: none;
}

/* https://tailwindcss.com/docs/reusing-styles#extracting-classes-with-apply */
.shipment-index-tab-input {
  @apply grid grid-cols-1 sm:grid-cols-[14ch_1fr] gap-2 sm:gap-4 items-center
}
